<template>
  <div>
    <div class="relative">
      <img
        :src="aboutHero"
        alt="Family on computer"
        class="object-cover object-center max-h-[400px] w-full"
      />
      <!-- <div class="absolute inset-0 bg-sky-900 bg-opacity-50"></div> -->
    </div>
    <section class="bg-brand-orange py-8 lg:py-20 relative">
      <div class="max-w-5xl mx-auto relative flex items-center">
        <div class="relative z-10 px-4 mb-4 w-full">
          <h2
            class="text-white text-center text-2xl md:text-4xl font-bold leading-normal"
          >
            Turbo Babies represents the rapid pace at which children birth to
            three develop and grow.
          </h2>
          <p
            class="text-lg md:text-2xl text-white text-center max-w-4xl font-normal mx-auto mt-8"
          >
            The campaign is fueled by the Juvenile Welfare Board of Pinellas
            County to encourage early connections, nurture a baby’s drive to
            learn, and support parents and other caregivers as their child’s
            first and best teachers.
          </p>
        </div>
      </div>
      <div class="bg-brand-purple h-[25px] absolute inset-x-0 bottom-0"></div>
    </section>

    <div
      class="pb-24 mx-auto max-w-7xl px-4 sm:px-6 flex flex-col lg:flex-row items-center lg:space-x-32 items-center mt-16"
    >
      <img
        :src="baby"
        alt="Child born"
        class="w-[250px] lg:w-full max-w-[500px] w-full"
      />
      <div class="relative z-10 max-w-2xl">
        <h2
          class="capitalize text-brand-orange text-2xl md:text-4xl font-bold leading-normal"
        >
          Learning begins at birth
        </h2>
        <p class="text-lg md:text-2xl text-gray-500 font-normal mt-8">
          From the earliest days of life, babies are active processors, not
          passive vessels. They are able to engage in give-and-take social
          exchanges with others. During the first 18 months, superhighways are
          paved in a baby’s brain – pathways that will last a lifetime.
        </p>
        <p class="text-lg md:text-2xl text-gray-500 font-normal mt-8">
          The first 1,000 days matter. At birth, a baby’s brain is about a
          quarter of the size of an adult’s brain. Incredibly, it doubles in
          size the first year, and keeps growing to about 80% of adult size by
          age three.
        </p>
        <p class="text-lg md:text-2xl text-gray-500 font-normal mt-8">
          A baby's early connections pave the way for healthy development and
          lifelong learning. Just as race car drivers need a pit crew to get to
          the finish line, it takes a village of caring adults, with parents and
          other caregivers steering and cheering the way!
        </p>
      </div>
    </div>
    <TurboTips :turboTips="turboTips" />

    <img :src="funkyRoad" alt="Funky road" class="hidden lg:block w-full" />

    <div class="bg-brand-purple lg:-mt-48 mb-24">
      <div class="mx-auto max-w-7xl py-16 px-4 sm:py-32 sm:px-6 lg:px-8">
        <div class="relative">
          <svg
            id="Group_9"
            data-name="Group 9"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 952.66 77.039"
            class="mx-auto hidden lg:block"
          >
            <path
              id="Path_5"
              data-name="Path 5"
              d="M175.37,77.028c-13.762-.182-30.337-.365-46.826-.641-14.2-.229-28.412-.641-42.689-.87-10.723-.182-21.522-.276-32.245-.411q-21.53-.273-43.061-.594a39.473,39.473,0,0,1-6.577-.641c-2.964-.552-4.517-2.333-3.7-3.979a19.53,19.53,0,0,1,2.736-4.167A278.658,278.658,0,0,1,28.457,40.84c.667-.6,1.326-1.146,1.993-1.693.372-.276.448-.505,0-.828Q20.027,29.468,10.7,20.069c-2.812-2.839-5.471-5.677-7.987-8.6A17.173,17.173,0,0,1,.2,7.533c-.743-1.51.667-3.156,3.251-3.844A21.428,21.428,0,0,1,7.67,3.049c4.8-.182,9.617-.276,14.421-.37,20.787-.271,41.651-.453,62.438-.776,13.02-.182,25.963-.594,38.907-.823C134.167.9,144.966.851,155.689.621c31-.547,61.914-.276,92.91-.047,17.832.094,35.731.505,53.555.828,8.139.135,16.279.453,24.418.594,5.767.089,11.61,0,17.385,0a48.764,48.764,0,0,1,9.026.87c1.849.365,2.583,1.281,2.735,2.333a41.678,41.678,0,0,1,.591,6.083c.076,5.078.076,10.156.076,15.234,0,10.109.144,20.177.068,30.286,0,4.76-.3,9.516-.515,14.276A4.681,4.681,0,0,1,355.423,73a3.484,3.484,0,0,1-2.744,1.557,55.445,55.445,0,0,1-10.723.729c-7.988,0-16.051.094-24.038.276-7.1.135-14.21.411-21.311.505-18.271.271-36.61.594-54.881.776-13.248.135-26.487.089-39.726.135C194,77.075,186.017,77.028,175.37,77.028Z"
              fill="#ffe11e"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M496.763.012c13.762.182,30.328.365,46.826.641,14.2.224,28.4.641,42.681.865,10.723.188,21.53.276,32.253.417q21.531.273,43.052.594a39.481,39.481,0,0,1,6.586.641c2.955.547,4.509,2.333,3.7,3.979a19.825,19.825,0,0,1-2.735,4.161A280.368,280.368,0,0,1,643.676,36.2c-.667.594-1.334,1.146-2,1.693-.363.276-.439.505,0,.823q10.436,8.852,19.757,18.255c2.812,2.839,5.471,5.672,7.987,8.6a17.351,17.351,0,0,1,2.516,3.932c.735,1.51-.667,3.156-3.259,3.844a21.823,21.823,0,0,1-4.213.641c-4.813.182-9.617.276-14.429.365-20.788.276-41.643.458-62.43.776-13.019.188-25.963.6-38.915.828-10.723.182-21.522.224-32.253.453-30.995.552-61.915.276-92.91.047-17.824-.088-35.723-.5-53.556-.823-8.139-.135-16.27-.458-24.409-.594-5.775-.094-11.618,0-17.385,0a49.153,49.153,0,0,1-9.026-.87c-1.849-.365-2.592-1.281-2.735-2.333a41.748,41.748,0,0,1-.591-6.083c-.076-5.078-.076-10.156-.076-15.24,0-10.109-.152-20.172-.076-30.287,0-4.755.3-9.516.523-14.271a4.681,4.681,0,0,1,.515-1.922,3.453,3.453,0,0,1,2.736-1.557,55.9,55.9,0,0,1,10.723-.734c8,0,16.059-.089,24.046-.271,7.1-.141,14.2-.411,21.3-.505C393.789.695,412.136.377,430.407.195,443.646.054,456.894.1,470.133.054,478.12-.034,486.108.012,496.763.012Z"
              fill="#ffe11e"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M777.3.012c13.754.182,30.328.365,46.818.641,14.21.224,28.411.641,42.689.865,10.723.188,21.522.276,32.253.417q21.517.273,43.052.594a39.4,39.4,0,0,1,6.578.641c2.963.547,4.517,2.333,3.706,3.979a19.53,19.53,0,0,1-2.744,4.161A279.077,279.077,0,0,1,924.212,36.2c-.667.594-1.334,1.146-2,1.693-.372.276-.448.505,0,.823q10.436,8.852,19.749,18.255c2.811,2.839,5.479,5.672,7.987,8.6a17.082,17.082,0,0,1,2.516,3.932c.743,1.51-.667,3.156-3.251,3.844a21.809,21.809,0,0,1-4.221.641c-4.805.182-9.617.276-14.421.365-20.788.276-41.651.458-62.438.776-13.02.188-25.963.6-38.907.828-10.723.182-21.53.224-32.253.453-30.995.552-61.915.276-92.91.047-17.832-.088-35.732-.5-53.556-.823-8.139-.135-16.278-.458-24.417-.594-5.767-.094-11.61,0-17.377,0a49.153,49.153,0,0,1-9.026-.87c-1.849-.365-2.592-1.281-2.744-2.333a41.884,41.884,0,0,1-.591-6.083c-.067-5.078-.067-10.156-.067-15.24,0-10.109-.152-20.172-.076-30.287,0-4.755.295-9.516.515-14.271a4.56,4.56,0,0,1,.523-1.922c.363-.828,1.4-1.328,2.736-1.557a55.847,55.847,0,0,1,10.723-.734c7.987,0,16.051-.089,24.046-.271,7.1-.141,14.2-.411,21.3-.505C674.325.695,692.672.377,710.943.195,724.182.054,737.421.1,750.66.054,758.656-.034,766.644.012,777.3.012Z"
              fill="#ffe11e"
            />
          </svg>
          <h2
            class="text-2xl md:text-4xl font-bold leading-normal text-center text-brand-purple lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:left-1/2 lg:-translate-x-1/2 z-10 lg:whitespace-nowrap bg-brand-yellow rounded-lg lg:bg-transparent px-6 py-3"
          >
            Campaign Champions
          </h2>
        </div>
        <div class="mt-8">
          <div
            class="mt-4 md:flex justify-center mx-auto space-y-16 md:space-y-0 flex-wrap"
          >
            <div
              v-for="logo in partners.logos"
              class="flex items-center md:w-1/4 justify-center mb-12 h-48"
            >
              <img
                :src="logo.image.url"
                :alt="logo.image.alt"
                class="w-[200px] h-auto"
                :style="{ width: logo.width + 'px' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import funkyRoad from '../assets/funkyRoad.png'
import baby from '../assets/baby.jpg'
import aboutHero from '../assets/aboutHero.jpg'
import aboutLine from '../assets/aboutLine.svg'
import turboFact from '../assets/turboFact.svg'
import aboutLineReversed from '../assets/aboutLineReversed.svg'
import racecar from '../assets/racer.svg'
import bottle from '../assets/bottle.png'
import TurboTips from '../components/TurboTips.vue'
let { turboTips } = defineProps(['turbo-tips'])

const partners = ref({})
onMounted(async () => {
  let data = await fetch('/wp-json/wp/v2/pages/5?acf_format=standard')
  data = await data.json()
  partners.value = data.acf.partners
})

function initRace1() {
  setTimeout(() => {
    gsap.to('#racecar1', {
      duration: 3,
      delay: 0.2,
      ease: 'power1.inOut',
      motionPath: {
        path: '#leftPath',
        align: '#leftPath',
        autoRotate: true,
        alignOrigin: [0.5, 0.5],
      },
    })
    gsap.to('#racecar2', {
      duration: 3,
      ease: 'power1.inOut',
      motionPath: {
        path: '#rightPath',
        align: '#rightPath',
        autoRotate: true,
        alignOrigin: [0.5, 0.5],
      },
    })
  }, 200)
}

function initRace2() {
  setTimeout(() => {
    gsap.to('#racecar3', {
      duration: 3,
      delay: 0.2,
      ease: 'power1.inOut',
      motionPath: {
        path: '#leftPath2',
        align: '#leftPath2',
        autoRotate: true,
        alignOrigin: [0.5, 0.5],
      },
    })
    gsap.to('#racecar4', {
      duration: 3,
      ease: 'power1.inOut',
      motionPath: {
        path: '#rightPath2',
        align: '#rightPath2',
        autoRotate: true,
        alignOrigin: [0.5, 0.5],
      },
    })
  }, 200)
}
</script>
