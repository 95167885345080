<template>
  <div>
    <div class="bg-brand-purple">
      <div class="relative">
        <img
          :src="parents"
          alt="Family on computer"
          class="object-cover object-center h-[400px] lg:h-auto lg:max-h-[450px] w-full"
        />
      </div>
      <img
        :src="funkyRoad"
        alt="Funky road"
        class="hidden lg:block lg:-mt-24 relative z-10 w-full"
      />

      <section
        class="py-8 lg:pt-0 lg:pb-32 px-4 lg:px-0 lg:-mt-24 relative z-10"
      >
        <div class="max-w-screen-xl mx-auto lg:flex justify-center">
          <div>
            <div class="relative lg:mb-8">
              <svg
                id="Group_9"
                data-name="Group 9"
                xmlns="http://www.w3.org/2000/svg"
                width="952.66"
                height="77.039"
                viewBox="0 0 952.66 77.039"
                class="mx-auto hidden lg:block"
              >
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M175.37,77.028c-13.762-.182-30.337-.365-46.826-.641-14.2-.229-28.412-.641-42.689-.87-10.723-.182-21.522-.276-32.245-.411q-21.53-.273-43.061-.594a39.473,39.473,0,0,1-6.577-.641c-2.964-.552-4.517-2.333-3.7-3.979a19.53,19.53,0,0,1,2.736-4.167A278.658,278.658,0,0,1,28.457,40.84c.667-.6,1.326-1.146,1.993-1.693.372-.276.448-.505,0-.828Q20.027,29.468,10.7,20.069c-2.812-2.839-5.471-5.677-7.987-8.6A17.173,17.173,0,0,1,.2,7.533c-.743-1.51.667-3.156,3.251-3.844A21.428,21.428,0,0,1,7.67,3.049c4.8-.182,9.617-.276,14.421-.37,20.787-.271,41.651-.453,62.438-.776,13.02-.182,25.963-.594,38.907-.823C134.167.9,144.966.851,155.689.621c31-.547,61.914-.276,92.91-.047,17.832.094,35.731.505,53.555.828,8.139.135,16.279.453,24.418.594,5.767.089,11.61,0,17.385,0a48.764,48.764,0,0,1,9.026.87c1.849.365,2.583,1.281,2.735,2.333a41.678,41.678,0,0,1,.591,6.083c.076,5.078.076,10.156.076,15.234,0,10.109.144,20.177.068,30.286,0,4.76-.3,9.516-.515,14.276A4.681,4.681,0,0,1,355.423,73a3.484,3.484,0,0,1-2.744,1.557,55.445,55.445,0,0,1-10.723.729c-7.988,0-16.051.094-24.038.276-7.1.135-14.21.411-21.311.505-18.271.271-36.61.594-54.881.776-13.248.135-26.487.089-39.726.135C194,77.075,186.017,77.028,175.37,77.028Z"
                  fill="#ffe11e"
                />
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M496.763.012c13.762.182,30.328.365,46.826.641,14.2.224,28.4.641,42.681.865,10.723.188,21.53.276,32.253.417q21.531.273,43.052.594a39.481,39.481,0,0,1,6.586.641c2.955.547,4.509,2.333,3.7,3.979a19.825,19.825,0,0,1-2.735,4.161A280.368,280.368,0,0,1,643.676,36.2c-.667.594-1.334,1.146-2,1.693-.363.276-.439.505,0,.823q10.436,8.852,19.757,18.255c2.812,2.839,5.471,5.672,7.987,8.6a17.351,17.351,0,0,1,2.516,3.932c.735,1.51-.667,3.156-3.259,3.844a21.823,21.823,0,0,1-4.213.641c-4.813.182-9.617.276-14.429.365-20.788.276-41.643.458-62.43.776-13.019.188-25.963.6-38.915.828-10.723.182-21.522.224-32.253.453-30.995.552-61.915.276-92.91.047-17.824-.088-35.723-.5-53.556-.823-8.139-.135-16.27-.458-24.409-.594-5.775-.094-11.618,0-17.385,0a49.153,49.153,0,0,1-9.026-.87c-1.849-.365-2.592-1.281-2.735-2.333a41.748,41.748,0,0,1-.591-6.083c-.076-5.078-.076-10.156-.076-15.24,0-10.109-.152-20.172-.076-30.287,0-4.755.3-9.516.523-14.271a4.681,4.681,0,0,1,.515-1.922,3.453,3.453,0,0,1,2.736-1.557,55.9,55.9,0,0,1,10.723-.734c8,0,16.059-.089,24.046-.271,7.1-.141,14.2-.411,21.3-.505C393.789.695,412.136.377,430.407.195,443.646.054,456.894.1,470.133.054,478.12-.034,486.108.012,496.763.012Z"
                  fill="#ffe11e"
                />
                <path
                  id="Path_7"
                  data-name="Path 7"
                  d="M777.3.012c13.754.182,30.328.365,46.818.641,14.21.224,28.411.641,42.689.865,10.723.188,21.522.276,32.253.417q21.517.273,43.052.594a39.4,39.4,0,0,1,6.578.641c2.963.547,4.517,2.333,3.706,3.979a19.53,19.53,0,0,1-2.744,4.161A279.077,279.077,0,0,1,924.212,36.2c-.667.594-1.334,1.146-2,1.693-.372.276-.448.505,0,.823q10.436,8.852,19.749,18.255c2.811,2.839,5.479,5.672,7.987,8.6a17.082,17.082,0,0,1,2.516,3.932c.743,1.51-.667,3.156-3.251,3.844a21.809,21.809,0,0,1-4.221.641c-4.805.182-9.617.276-14.421.365-20.788.276-41.651.458-62.438.776-13.02.188-25.963.6-38.907.828-10.723.182-21.53.224-32.253.453-30.995.552-61.915.276-92.91.047-17.832-.088-35.732-.5-53.556-.823-8.139-.135-16.278-.458-24.417-.594-5.767-.094-11.61,0-17.377,0a49.153,49.153,0,0,1-9.026-.87c-1.849-.365-2.592-1.281-2.744-2.333a41.884,41.884,0,0,1-.591-6.083c-.067-5.078-.067-10.156-.067-15.24,0-10.109-.152-20.172-.076-30.287,0-4.755.295-9.516.515-14.271a4.56,4.56,0,0,1,.523-1.922c.363-.828,1.4-1.328,2.736-1.557a55.847,55.847,0,0,1,10.723-.734c7.987,0,16.051-.089,24.046-.271,7.1-.141,14.2-.411,21.3-.505C674.325.695,692.672.377,710.943.195,724.182.054,737.421.1,750.66.054,758.656-.034,766.644.012,777.3.012Z"
                  fill="#ffe11e"
                />
              </svg>
              <h2
                class="text-2xl md:text-4xl font-bold leading-normal text-center text-brand-purple lg:absolute top-1/2 lg:-translate-y-1/2 left-1/2 lg:-translate-x-1/2 z-10 lg:whitespace-nowrap bg-brand-yellow px-4 py-2 lg:bg-transparent lg:p-0 rounded-lg"
              >
                Partner Toolkit
              </h2>
            </div>

            <div
              v-for="toolkit in toolkits"
              class="bg-white rounded-xl p-8 mt-4"
            >
              <h2
                class="text-brand-dark-blue text-2xl font-bold leading-normal mb-4"
              >
                {{ toolkit.title }}
              </h2>
              <div
                v-html="toolkit.body"
                class="text-lg text-brand-dark-blue font-normal"
              ></div>
              <div class="text-right flex-shrink-0">
                <a
                  v-if="toolkit.link"
                  :href="toolkit.link.url"
                  class="text-white text-lg inline-flex py-3 px-6 items-center space-x-2 group bg-brand-dark-blue rounded-full font-bold cursor-pointer hover:bg-brand-dark-blue/75"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6 opacity-75 -mt-1"
                  >
                    <path
                      d="M12 1.5a.75.75 0 01.75.75V7.5h-1.5V2.25A.75.75 0 0112 1.5zM11.25 7.5v5.69l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z"
                    />
                  </svg>
                  <span>{{ toolkit.link.title }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <section class="bg-brand-purple px-6 lg:px-0">
    <img
      :src="funkyRoad"
      alt="Funky road"
      class="hidden lg:block relative z-10 w-full"
    />

    <div class="max-w-3xl mx-auto pb-16 lg:-mt-12 xl:-mt-24 relative z-10">
      <div class="border-brand-yellow border-8 rounded-xl p-8 bg-white">
        <h2 class="font-bold text-3xl">{{ forms.partner_form.headline }}</h2>
        <div
          v-html="forms.partner_form.body"
          class="text-zinc-600 mt-1 prose !max-w-none"
        ></div>
        <div
          id="partnerForm"
          class="!max-w-none prose prose-li:list-none prose-li:p-0 prose-ul:m-0 prose-ul:p-0 [&_input[type='text']]:w-full [&_input[type='text']]:mt-1 [&_input[type='tel']]:w-full [&_input[type='tel']]:mt-1 [&_input[type='email']]:w-full [&_input[type='email']]:mt-1 [&_input[type='number']]:w-full [&_input[type='number']]:mt-1 [&_input[type='checkbox']]:mt-1 [&_input[type='radio']]:mt-1 [&_select]:w-full [&_select]:mt-1 [&_label]:font-bold [&_.name_last>label]:font-normal [&_.name_first>label]:font-normal [&_.ginput_container_checkbox_label]:font-normal [&_.ginput_container_checkbox_label]:ml-2 [&_.gform_footer]:text-center [&_.gform_footer]:mt-8 [&_.gfield]:mb-8 [&_.ginput_complex]:mt-1 [&_.address\_line\_2_label]:font-normal [&_.address\_line\_2_label]:text-sm [&_.address\_line\_2_label]:block [&_.address\_line\_2_label]:mb-2 [&_.address\_line\_1_label]:font-normal [&_.address\_line\_1_label]:text-sm [&_.address\_line\_1_label]:block [&_.address\_line\_1_label]:mb-2 [&_.address\_city_label]:font-normal [&_.address\_city_label]:text-sm [&_.address\_city_label]:block [&_.address\_city_label]:mb-2 [&_.address\_state_label]:font-normal [&_.address\_state_label]:text-sm [&_.address\_state_label]:block [&_.address\_state_label]:mb-2 [&_.address\_zip_label]:font-normal [&_.address\_zip_label]:text-sm [&_.address\_zip_label]:block [&_.address\_zip_label]:mb-2 [&_.name\_first_label]:font-normal [&_.name\_first_label]:text-sm [&_.name\_first_label]:block [&_.name\_first_label]:mb-2 [&_.name\_last_label]:font-normal [&_.name\_last_label]:text-sm [&_.name\_last_label]:block [&_.name\_last_label]:mb-2"
        ></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineProps } from 'vue'
import funkyRoad from '../assets/funkyRoad.png'
import parents from '../assets/parents.jpg'
let { forms } = defineProps(['forms'])

import { ref, computed, onMounted, getCurrentInstance } from 'vue'
const instance = getCurrentInstance()

let toolkits = ref({})

onMounted(async () => {
  try {
    let response = await fetch(
      instance.proxy.baseUrl + '/wp-json/wp/v2/pages/98',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    response = await response.json()
    toolkits.value = response.acf?.toolkits
    document
      .querySelector('#partnerForm')
      .appendChild(
        document.querySelector('#partnerFormGravity #gform_wrapper_1'),
      )
  } catch (err) {
    console.log(err)
  }
})
</script>
