<template>
  <div class="prose mx-auto py-24 px-4">
    <h1 v-if="data.title">{{ data.title.rendered }}</h1>
    <div v-if="data.content" v-html="data.content.rendered"></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const data = ref({})
const currentTab = ref('parent')
onMounted(async () => {
  let res = await fetch('/wp-json/wp/v2/pages/301')
  res = await res.json()
  data.value = res
})
</script>
