import './style.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { createApp } from 'vue'
import { createRouter, createMemoryHistory } from 'vue-router'

import SiteHeader from './components/SiteHeader.vue'
import PageIndex from './pages/index.vue'
import About from './pages/about.vue'
import Activities from './pages/activities.vue'
import LandingPage from './pages/LandingPage.vue'
import SiteFooter from './components/SiteFooter.vue'
import joinOurPitCrew from './pages/joinOurPitCrew.vue'
import CommunityNews from './pages/CommunityNews.vue'
import parentResources from './pages/parentResources.vue'
import partnerToolkit from './pages/partnerToolkit.vue'
import PrivacyPolicy from './components/PrivacyPolicy.vue'
import TermsOfUse from './components/TermsOfUse.vue'
import TurboTips from './components/TurboTips.vue'
import FunEverydayActivities from './components/FunEverydayActivities.vue'
import FunkyRoad from './components/FunkyRoad.vue'
import ActivitiesIndex from './components/ActivitiesIndex.vue'
import CheckpointMilestone from './components/CheckpointMilestone.vue'
import { ObserveVisibility } from 'vue-observe-visibility'

// create web history if production, otherwise use hash history
// const history =
//   process.env.NODE_ENV === 'production'
//     ? createWebHistory()
//     : createWebHashHistory()

const router = createRouter({
  history: createMemoryHistory(),
  routes: [{ path: '/activities/:id', component: Activities }],
})

const app = createApp({
  components: {
    SiteHeader,
    PageIndex,
    About,
    SiteFooter,
    Activities,
    joinOurPitCrew,
    CommunityNews,
    parentResources,
    partnerToolkit,
    Activities,
    PrivacyPolicy,
    TermsOfUse,
    TurboTips,
    FunEverydayActivities,
    FunkyRoad,
    ActivitiesIndex,
    CheckpointMilestone,
    LandingPage,
  },
  data() {
    return {
      contactButton: 1,
    }
  },
})
app.use(router)

// Get the base_url value from the wpGlobals object.
const baseUrl = wpGlobals.baseUrl

// Create a global mixin to make the base_url value available in all components.
const globalMixin = {
  data() {
    return {
      baseUrl,
    }
  },
}

// Use the global mixin.
app.mixin(globalMixin)

app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance
    ObserveVisibility.bind(el, binding, vnode)
  },
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
})

app.mount('#app')

// const footer = createApp({})
// footer.component('SiteFooter', SiteFooter)
// footer.mount('#footer')
