<template>
  <section class="py-24">
    <div class="max-w-screen-xl mx-auto px-4">
      <div class="relative mb-8">
        <svg
          id="Group_9"
          data-name="Group 9"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 952.66 77.039"
          class="mx-auto hidden lg:block"
        >
          <path
            id="Path_5"
            data-name="Path 5"
            d="M175.37,77.028c-13.762-.182-30.337-.365-46.826-.641-14.2-.229-28.412-.641-42.689-.87-10.723-.182-21.522-.276-32.245-.411q-21.53-.273-43.061-.594a39.473,39.473,0,0,1-6.577-.641c-2.964-.552-4.517-2.333-3.7-3.979a19.53,19.53,0,0,1,2.736-4.167A278.658,278.658,0,0,1,28.457,40.84c.667-.6,1.326-1.146,1.993-1.693.372-.276.448-.505,0-.828Q20.027,29.468,10.7,20.069c-2.812-2.839-5.471-5.677-7.987-8.6A17.173,17.173,0,0,1,.2,7.533c-.743-1.51.667-3.156,3.251-3.844A21.428,21.428,0,0,1,7.67,3.049c4.8-.182,9.617-.276,14.421-.37,20.787-.271,41.651-.453,62.438-.776,13.02-.182,25.963-.594,38.907-.823C134.167.9,144.966.851,155.689.621c31-.547,61.914-.276,92.91-.047,17.832.094,35.731.505,53.555.828,8.139.135,16.279.453,24.418.594,5.767.089,11.61,0,17.385,0a48.764,48.764,0,0,1,9.026.87c1.849.365,2.583,1.281,2.735,2.333a41.678,41.678,0,0,1,.591,6.083c.076,5.078.076,10.156.076,15.234,0,10.109.144,20.177.068,30.286,0,4.76-.3,9.516-.515,14.276A4.681,4.681,0,0,1,355.423,73a3.484,3.484,0,0,1-2.744,1.557,55.445,55.445,0,0,1-10.723.729c-7.988,0-16.051.094-24.038.276-7.1.135-14.21.411-21.311.505-18.271.271-36.61.594-54.881.776-13.248.135-26.487.089-39.726.135C194,77.075,186.017,77.028,175.37,77.028Z"
            fill="#ff6633"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M496.763.012c13.762.182,30.328.365,46.826.641,14.2.224,28.4.641,42.681.865,10.723.188,21.53.276,32.253.417q21.531.273,43.052.594a39.481,39.481,0,0,1,6.586.641c2.955.547,4.509,2.333,3.7,3.979a19.825,19.825,0,0,1-2.735,4.161A280.368,280.368,0,0,1,643.676,36.2c-.667.594-1.334,1.146-2,1.693-.363.276-.439.505,0,.823q10.436,8.852,19.757,18.255c2.812,2.839,5.471,5.672,7.987,8.6a17.351,17.351,0,0,1,2.516,3.932c.735,1.51-.667,3.156-3.259,3.844a21.823,21.823,0,0,1-4.213.641c-4.813.182-9.617.276-14.429.365-20.788.276-41.643.458-62.43.776-13.019.188-25.963.6-38.915.828-10.723.182-21.522.224-32.253.453-30.995.552-61.915.276-92.91.047-17.824-.088-35.723-.5-53.556-.823-8.139-.135-16.27-.458-24.409-.594-5.775-.094-11.618,0-17.385,0a49.153,49.153,0,0,1-9.026-.87c-1.849-.365-2.592-1.281-2.735-2.333a41.748,41.748,0,0,1-.591-6.083c-.076-5.078-.076-10.156-.076-15.24,0-10.109-.152-20.172-.076-30.287,0-4.755.3-9.516.523-14.271a4.681,4.681,0,0,1,.515-1.922,3.453,3.453,0,0,1,2.736-1.557,55.9,55.9,0,0,1,10.723-.734c8,0,16.059-.089,24.046-.271,7.1-.141,14.2-.411,21.3-.505C393.789.695,412.136.377,430.407.195,443.646.054,456.894.1,470.133.054,478.12-.034,486.108.012,496.763.012Z"
            fill="#ff6633"
          />
          <path
            id="Path_7"
            data-name="Path 7"
            d="M777.3.012c13.754.182,30.328.365,46.818.641,14.21.224,28.411.641,42.689.865,10.723.188,21.522.276,32.253.417q21.517.273,43.052.594a39.4,39.4,0,0,1,6.578.641c2.963.547,4.517,2.333,3.706,3.979a19.53,19.53,0,0,1-2.744,4.161A279.077,279.077,0,0,1,924.212,36.2c-.667.594-1.334,1.146-2,1.693-.372.276-.448.505,0,.823q10.436,8.852,19.749,18.255c2.811,2.839,5.479,5.672,7.987,8.6a17.082,17.082,0,0,1,2.516,3.932c.743,1.51-.667,3.156-3.251,3.844a21.809,21.809,0,0,1-4.221.641c-4.805.182-9.617.276-14.421.365-20.788.276-41.651.458-62.438.776-13.02.188-25.963.6-38.907.828-10.723.182-21.53.224-32.253.453-30.995.552-61.915.276-92.91.047-17.832-.088-35.732-.5-53.556-.823-8.139-.135-16.278-.458-24.417-.594-5.767-.094-11.61,0-17.377,0a49.153,49.153,0,0,1-9.026-.87c-1.849-.365-2.592-1.281-2.744-2.333a41.884,41.884,0,0,1-.591-6.083c-.067-5.078-.067-10.156-.067-15.24,0-10.109-.152-20.172-.076-30.287,0-4.755.295-9.516.515-14.271a4.56,4.56,0,0,1,.523-1.922c.363-.828,1.4-1.328,2.736-1.557a55.847,55.847,0,0,1,10.723-.734c7.987,0,16.051-.089,24.046-.271,7.1-.141,14.2-.411,21.3-.505C674.325.695,692.672.377,710.943.195,724.182.054,737.421.1,750.66.054,758.656-.034,766.644.012,777.3.012Z"
            fill="#ff6633"
          />
        </svg>
        <h2
          class="text-2xl md:text-4xl font-bold leading-normal text-center text-white lg:absolute top-1/2 lg:-translate-y-1/2 left-1/2 lg:-translate-x-1/2 z-10 lg:whitespace-nowrap bg-brand-orange rounded-lg lg:bg-transparent px-4 lg:px-0 py-4 lg:py-0"
        >
          {{ turboTips.headline }}
        </h2>
      </div>

      <div class="flex flex-wrap">
        <div
          v-for="(tip, i) in turboTips.tips"
          class="group w-full sm:w-1/2 lg:w-1/4 flex flex-col items-center space-y-4 relative mb-8"
        >
          <div
            class="opacity-0 scale-90 group-hover:scale-100 transition duration-200 group-hover:opacity-100 min-h-[330px] h-full p-8 rounded-xl absolute bottom-[50px] border-8 bg-white shadow-sm z-10 text-lg"
            :style="{ borderColor: getColor(i) }"
            v-html="tip.body"
          ></div>
          <img :src="badges[i].url" alt="tip" class="max-w-[250px] w-full" />
          <h2
            class="text-center font-bold text-3xl"
            :style="{ color: getColor(i) }"
          >
            {{ tip.headline }}
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue'
import jwbLogoWhite from '../assets/jwbLogoWhite.svg'
import sunrays from '../assets/sunrays.png'
import checkeredFlag from '../assets/checkeredFlag.svg'
import critter1 from '../assets/critter1.svg'
import badge1 from '../assets/badge1.png'
import badge2 from '../assets/badge2.png'
import badge3 from '../assets/badge3.png'
import badge4 from '../assets/badge4.png'
import funkyRoad from '../assets/funkyRoad.png'

import car1 from '../assets/car1.svg'
import car2 from '../assets/car2.svg'
import car3 from '../assets/car3.svg'

let { turboTips } = defineProps(['turbo-tips'])

const badges = [
  {
    url: badge1,
    text: 'Tune In',
    color: '#993795',
    body: `Your baby thrives on doing things with you – cuddling, playing, talking, singing, reading. Tune-in and listen to your baby’s communication cues, such as coos, cries, laughs, sounds, and words. Your baby is also naturally curious. Notice what he or she is focused on, and react to that.`,
  },
  {
    url: badge2,
    text: 'Talk & Repeat',
    color: '#95C93F',
    body: `Talk often using real words and a positive tone. Be your baby’s storyteller by talking about your everyday routines: describe what you are doing, seeing, or what’s going to happen next. Sing songs, read books, make it fun! Your baby learns best through repetition; pathways are paved in your baby’s brain when you repeat what you say or do.`,
  },
  {
    url: badge3,
    text: 'Take Turns',
    color: '#1B99D5',
    body: `Have a conversation with your baby. After you talk, pause and allow time for your baby to respond with sounds, gestures, and words. Ask lots of questions. When you tune-in and take turns, you’ll see your baby has a lot to say!`,
  },
  {
    url: badge4,
    text: 'Take Time',
    color: '#F26C3F',
    body: `The routines of daily life – like meal time, bath time, grocery shopping, even diaper changes – offer endless chances to talk and interact with your baby. Think of these as “pit stops” during your day when you can practice tuning in, talking often, and taking turns!`,
  },
]

function getColor(index) {
  const colors = ['#993795', '#95C93F', '#1B99D5', '#F26C3F']
  return colors[index]
}
</script>
