<template>
  <div
    class="fixed inset-0 md:flex flex-col md:flex-row md:items-center justify-center h-xl h-screen z-50 py-4 md:py-12 overflow-scroll lg:overflow-auto"
  >
    <div
      class="bg-black bg-opacity-75 z-10 fixed inset-0"
      @click="$emit('close')"
    />

    <div
      class="w-full max-w-[760px] relative z-10 mx-auto mt-8 md:m-auto mb-24 modal"
    >
      <div class="bg-white shadow-md overflow-hidden relative rounded-md">
        <div
          :style="`background-image: url('${sunrays}');`"
          class="absolute inset-x-0 top-0 h-[400px] bg-cover"
        ></div>
        <div
          class="absolute inset-x-0 top-0 h-[600px] bg-gradient-to-t from-white via-white to-transparent"
        ></div>
        <img
          class="h-16 block xl:h-32 relative z-10 mx-auto mt-8"
          :src="logo"
          alt="Turbo Babies Logo"
        />

        <div class="relative z-10 lg:mx-12 mt-12 p-8 bg-white rounded-md">
          <h2
            class="text-brand-purple text-4xl uppercase font-bold text-center"
          >
            CLAIM YOUR BABY’S FREE BIRTHDAY GIFT!
          </h2>
          <div class="text-center text-3xl mt-12">
            Join the Pit Crew and get a free birthday gift for your baby sent to
            your home (ages 0 to 3).
          </div>
          <div class="text-center mt-8">
            <a
              href="/join-the-pitcrew"
              class="inline-block bg-brand-purple hover:bg-brand-purple/80 transition px-6 py-3 text-center text-white font-bold rounded-full"
              >JOIN THE PIT CREW</a
            >
          </div>
          <svg
            id="Group_10"
            data-name="Group 10"
            xmlns="http://www.w3.org/2000/svg"
            class="w-48 mx-auto mt-8 block"
            viewBox="0 0 1940.9 588.221"
          >
            <path
              id="Path_1"
              data-name="Path 1"
              d="M1045.95,235.635a38.226,38.226,0,1,0-43.83,31.635A38.224,38.224,0,0,0,1045.95,235.635Z"
              fill="#f3673a"
            />
            <path
              id="Path_2"
              data-name="Path 2"
              d="M1115.78,439.432c-23.95,0-41.53-9.769-41.53-30.3,0-18.076,21.33-29.832,36.98-29.832h-7.64c10.76,0,26.22-6.844,26.22-24.424V131.97a37.15,37.15,0,0,1,74.3,0V355.343c0,57.679-38.45,84.089-87.33,84.089Z"
              fill="#f3673a"
            />
            <path
              id="Path_3"
              data-name="Path 3"
              d="M1308.66,331.416l-64.03-184.764c-1.47-4.388-3.43-11.729-3.43-18.076,0-17.11,13.69-33.724,35.69-33.724,18.58,0,30.8,11.729,36.18,28.839l40.08,132.964,41.07-132.467c5.38-17.607,19.07-29.805,38.12-29.805h4.38c19.08,0,32.77,12.225,38.12,29.805l41.54,132.936,41.07-133.93c4.88-15.647,16.15-28.342,35.69-28.342,20.53,0,34.72,15.648,34.72,34.221a64.278,64.278,0,0,1-2.46,15.151l-65.99,187.22c-7.81,22.961-22.96,33.227-41.07,33.227h-2.46c-18.08,0-33.23-10.266-40.1-31.764l-41.54-131.971-42.03,131.971c-6.85,21.5-22,31.764-40.11,31.764h-2.45c-18.08,0-33.24-10.266-41.07-33.227Z"
              fill="#f3673a"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M1665.67,37.146a37.15,37.15,0,0,1,74.3,0v98.247c18.08-24.424,43.03-42.528,81.64-42.528,61.11,0,119.29,47.909,119.29,135.393v.966c0,87.483-57.19,135.392-119.29,135.392-39.61,0-64.03-18.076-81.64-39.105v.5c0,20.036-16.62,36.65-37.15,36.65a37.123,37.123,0,0,1-37.15-37.146V37.146ZM1866.6,229.251v-.966c0-43.493-29.31-72.36-64.03-72.36S1739,184.764,1739,228.285v.966c0,43.494,28.84,72.36,63.57,72.36S1866.6,273.269,1866.6,229.251Z"
              fill="#f3673a"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M911.181,358.407c10.957,4.139,16.395,11.949,18.547,20.063q83.091,205.946,281.692,106.47c7.01-4.664,16.2-6.926,27.21-2.787,14.16,5.354,23.49,23.513,18.3,37.229-2.95,7.782-7.62,13.3-9.88,15.068q-274.53,156.477-376.828-142.4c-.5-2.815-.387-10.073,2.567-17.856,5.188-13.716,24.233-21.139,38.392-15.785C897.022,353.053,922.138,362.546,911.181,358.407Z"
              fill="#f3673a"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M0,116.659a8.643,8.643,0,0,1,8.777-8.776h57.8a7.934,7.934,0,0,1,0,15.868H17.416V150.8H60.2a7.921,7.921,0,1,1,0,15.841H17.416v32.426A8.581,8.581,0,0,1,8.777,207.7,8.717,8.717,0,0,1,0,199.064Z"
              fill="#f3673a"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M91.356,164.955V115.8a8.613,8.613,0,0,1,8.777-8.638,8.513,8.513,0,0,1,8.639,8.638v48.461c0,18.407,9.494,28.177,25.088,28.177s24.924-9.218,24.924-27.487V115.8a8.612,8.612,0,0,1,8.777-8.638A8.513,8.513,0,0,1,176.2,115.8v48.323c0,29.612-16.726,44.487-42.643,44.487s-42.229-14.875-42.229-43.632Z"
              fill="#f3673a"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M200.16,198.264V116.659a8.643,8.643,0,0,1,8.777-8.776H266.6a7.782,7.782,0,1,1,0,15.565H217.576v25.8h42.643A7.876,7.876,0,0,1,268,157.172a7.639,7.639,0,0,1-7.783,7.644H217.576v26.632h49.736a7.782,7.782,0,1,1,0,15.564H208.937a8.643,8.643,0,0,1-8.777-8.775Z"
              fill="#f3673a"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M295.078,115.8a8.613,8.613,0,0,1,8.777-8.638,8.513,8.513,0,0,1,8.639,8.638v75.368H357.4a7.921,7.921,0,1,1,0,15.841H303.855a8.643,8.643,0,0,1-8.777-8.776Z"
              fill="#f3673a"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M382.762,198.264V116.659a8.643,8.643,0,0,1,8.777-8.776H449.2a7.782,7.782,0,1,1,0,15.565H400.178v25.8H442.82a7.876,7.876,0,0,1,7.784,7.921,7.639,7.639,0,0,1-7.784,7.644H400.178v26.632h49.736a7.782,7.782,0,1,1,0,15.564H391.539a8.643,8.643,0,0,1-8.777-8.775Z"
              fill="#f3673a"
            />
            <path
              id="Path_11"
              data-name="Path 11"
              d="M477.68,116.659a8.643,8.643,0,0,1,8.777-8.776h28.18c31.161,0,52.717,21.388,52.717,49.289v.276c0,27.9-21.529,49.592-52.717,49.592h-28.18a8.643,8.643,0,0,1-8.777-8.776Zm36.984,74.513c20.839,0,34.418-14.02,34.418-33.421v-.276c0-19.4-13.607-33.724-34.418-33.724H495.123v67.421Z"
              fill="#f3673a"
            />
            <path
              id="Path_12"
              data-name="Path 12"
              d="M636.469,116.659a8.643,8.643,0,0,1,8.777-8.776h35.687c11.344,0,20.259,3.118,25.917,8.776a22.194,22.194,0,0,1,6.652,16.42v.276c0,11.9-6.79,18.27-14.159,22.106,11.619,3.974,19.706,10.625,19.706,24.092v.276c0,17.718-14.6,27.211-36.681,27.211H645.246a8.643,8.643,0,0,1-8.777-8.776ZM677.4,149.665c11.206,0,18.713-4.388,18.713-13.467v-.276c0-7.783-6.237-12.612-17.416-12.612H653.636v26.355H677.4Zm5.106,41.921c11.9,0,19.127-4.664,19.127-13.606V177.7c0-8.362-6.651-13.329-20.4-13.329H653.609v27.211Z"
              fill="#f3673a"
            />
            <path
              id="Path_13"
              data-name="Path 13"
              d="M762.848,168.514l-34.142-46.887a10.791,10.791,0,0,1-1.987-6.1,8.489,8.489,0,0,1,8.777-8.362c3.836,0,6.375,2.125,8.5,5.243L771.625,152.2l28.042-39.961c2.125-3.118,4.664-5.243,8.363-5.243a8.267,8.267,0,0,1,8.5,8.5,10.654,10.654,0,0,1-2.4,6.237l-33.865,46.336v31.019a8.613,8.613,0,0,1-8.777,8.638,8.513,8.513,0,0,1-8.639-8.638V168.514Z"
              fill="#f3673a"
            />
          </svg>
        </div>

        <div
          class="absolute top-0 right-0 z-10 h-12 w-12 flex items-center justify-center bg-white"
        >
          <button
            ref="exit"
            type="button"
            class="text-teal-800 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150 dark:hover:text-gray-100"
            @click="$emit('close')"
          >
            <svg
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import sunrays from '../assets/sunrays2.png'
import logo from '../assets/logo.png'
import jwbLogo from '../assets/jwbLogo.png'
const emits = defineEmits('close')

console.log(sunrays)
const open = ref(true)
</script>

<style></style>
