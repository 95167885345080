<template>
  <div class="mb-24">
    <TransitionModal>
      <HomeModal v-if="homeModalOpen" @close="homeModalOpen = false" />
    </TransitionModal>
    <div class="max-h-[700px] overflow-hidden relative">
      <img
        :src="manAndBabyLg"
        class="object-cover object-center w-full lg:-mt-40"
        alt="Man playing with baby"
      />

      <main class="absolute inset-0 z-10 flex items-center">
        <div
          class="text-left bg-brand-yellow p-4 lg:p-8 rounded-r-lg border-b-[15px] border-brand-purple mt-16 bg-cover bg-right bg-opacity-75"
        >
          <div class="max-w-[650px] w-full">
            <h1 class="text-lg text-brand-purple md:text-4xl mb-1">
              <p
                class="font-bold decoration-brand-brand-purple font-extrabold uppercase"
              >
                Supercharge everyday moments
              </p>
            </h1>
            <p
              class="font-semibold text-lg text-brand-purple capitalize lg:text-3xl"
            >
              Give Your Baby A Great Start In Life
            </p>
          </div>
        </div>
      </main>
    </div>
    <section class="bg-brand-orange md:-mt-4 relative py-12 md:py-0">
      <div class="max-w-5xl mx-auto relative flex items-center">
        <div class="relative z-10 px-4 mb-4 w-full">
          <h2
            class="text-white text-center text-2xl lg:text-4xl font-bold leading-normal"
          >
            80% of brain growth happens in baby’s first 1,000 days.
          </h2>
          <p
            class="text-lg lg:text-2xl text-white text-center max-w-3xl font-normal mx-auto mt-8"
          >
            Turbo Babies promotes early connections, nurtures your baby’s drive
            to learn, and supports YOU as your child’s first and best teacher!
          </p>
        </div>
        <img :src="road" alt="road" class="relative z-10 hidden md:block" />
      </div>
      <div class="bg-brand-purple h-[25px] absolute inset-x-0 bottom-0"></div>
    </section>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import hero from '../assets/hero.mp4'
import manAndBabyLg from '../assets/manAndBabyLg.jpg'
import sunrays from '../assets/sunrays.png'
import road from '../assets/road.svg'
import HomeModal from '@/components/HomeModal.vue'
import TransitionModal from '@/components/transitions/TransitionModal.vue'
const homeModalOpen = ref(false)

const checkScroll = () => {
  const scrollTop = window.scrollY
  const docHeight = document.documentElement.scrollHeight
  const winHeight = window.innerHeight
  const scrollPercent = (scrollTop / (docHeight - winHeight)) * 100

  if (scrollPercent >= 20) {
    homeModalOpen.value = true
    window.removeEventListener('scroll', checkScroll) // Remove the event listener after the condition is met
  }
}

onMounted(() => {
  window.addEventListener('scroll', checkScroll)
})
</script>
