<template>
  <div>
    <TurboTips
      :turboTips="turboTips"
      v-if="
        ![
          '/about',
          '/activities/0-1',
          '/activities/1-2',
          '/activities/2-3',
          '/partner-toolkit',
        ]
      "
    />

    <section v-if="'/join-the-pitcrew'" class="bg-white bg-cover bg-top py-24">
      <div class="overflow-hidden w-full mx-auto animate-raceRight">
        <img :src="critter1" alt="Critter Driving" class="w-32 mb-4" />
      </div>
      <div
        class="mx-auto max-w-2xl px-4 sm:px-6 text-center relative text-center"
      >
        <img
          :src="checkeredFlag"
          alt="Checkered Flag"
          class="hidden lg:block w-[130px] top-1/2 -translate-y-1/2 absolute left-[100%]"
        />
        <img
          :src="checkeredFlag"
          alt="Checkered Flag"
          class="hidden lg:block w-[130px] top-1/2 -translate-y-1/2 absolute right-[100%] scale-x-[-1]"
        />

        <div class="relative inline-block">
          <h2
            class="text-brand-orange text-2xl md:text-4xl font-bold leading-normal mb-12"
          >
            Join the Turbo Babies Pit Crew!
          </h2>
          <div class="mt-8">
            <span class="inline-flex shadow rounded-full">
              <a
                href="/join-the-pitcrew"
                class="block w-full bg-brand-orange px-12 py-5 text-center text-white hover:bg-brand-orange/75 rounded-full font-bold"
                >JOIN NOW</a
              >
            </span>
          </div>
        </div>
      </div>
    </section>
    <footer class="bg-brand-blue">
      <div class="py-24 mx-auto max-w-7xl px-4 sm:px-6">
        <div>
          <img :src="jwbLogoWhite" alt="JWB Logo" />
        </div>
        <div class="lg:flex lg:space-x-24 mt-8">
          <div class="space-y-2 max-w-xl w-full">
            <div>
              <div class="whitespace-nowrap text-white">
                14155 58th Street North, Clearwater, FL 33760
              </div>
            </div>
            <div>
              <a href="tel:7274535600" class="text-white">P: 727.453.5600</a>
            </div>
            <div>
              <a href="tel:7274535610" class="text-white">F: 727.453.5610</a>
            </div>
            <div>
              <a href="mailto:TurboBabies@jwbpinellas.org" class="text-white"
                >E: TurboBabies@jwbpinellas.org</a
              >
            </div>
            <div class="text-white max-w-[400px]">
              Parents and caregivers, we want to hear from you!
            </div>
          </div>
          <div>
            <div class="lg:flex lg:space-x-24 mb-12">
              <div class="max-w-lg w-full space-x-12 text-base">
                <a href="/privacy-policy" class="text-white">Privacy Policy</a>
                <a href="/terms-of-use" class="text-white">Terms of Use</a>
                <a
                  class="text-white"
                  href="https://app.keysurvey.com/f/41691396/c835/"
                  target="_blank"
                  >Take Our Survey</a
                >
              </div>
              <div></div>
              <div class="md:w-64 hidden"></div>
            </div>
            <slot />
          </div>
        </div>
      </div>
    </footer>
    <footer class="bg-brand-dark-blue p-">
      <div class="py-12 mx-auto max-w-7xl px-4 sm:px-6">
        <p class="text-white text-base">
          © 2022 Juvenile Welfare Board of Pinellas County. All rights reserved.
        </p>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue'
import jwbLogoWhite from '../assets/jwbLogoWhite.svg'
import sunrays from '../assets/sunrays.png'
import checkeredFlag from '../assets/checkeredFlag.svg'
import critter1 from '../assets/critter1.svg'
import badge1 from '../assets/badge1.png'
import badge2 from '../assets/badge2.png'
import badge3 from '../assets/badge3.png'
import badge4 from '../assets/badge4.png'
import TurboTips from '../components/TurboTips.vue'

import car1 from '../assets/car1.svg'
import car2 from '../assets/car2.svg'
import car3 from '../assets/car3.svg'

let { turboTips } = defineProps(['turbo-tips'])

const badges = [
  {
    url: badge1,
    text: 'Tune In',
    color: '#993795',
    body: `Your baby thrives on doing things with you – cuddling, playing, talking, singing, reading. Tune-in and listen to your baby’s communication cues, such as coos, cries, laughs, sounds, and words. Your baby is also naturally curious. Notice what he or she is focused on, and react to that.`,
  },
  {
    url: badge2,
    text: 'Talk & Repeat',
    color: '#95C93F',
    body: `Talk often using real words and a positive tone. Be your baby’s storyteller by talking about your everyday routines: describe what you are doing, seeing, or what’s going to happen next. Sing songs, read books, make it fun! Your baby learns best through repetition; pathways are paved in your baby’s brain when you repeat what you say or do.`,
  },
  {
    url: badge3,
    text: 'Take Turns',
    color: '#1B99D5',
    body: `Have a conversation with your baby. After you talk, pause and allow time for your baby to respond with sounds, gestures, and words. Ask lots of questions. When you tune-in and take turns, you’ll see your baby has a lot to say!`,
  },
  {
    url: badge4,
    text: 'Take Time',
    color: '#F26C3F',
    body: `The routines of daily life – like meal time, bath time, grocery shopping, even diaper changes – offer endless chances to talk and interact with your baby. Think of these as “pit stops” during your day when you can practice tuning in, talking often, and taking turns!`,
  },
]

function getColor(index) {
  const colors = ['#993795', '#95C93F', '#1B99D5', '#F26C3F']
  return colors[index]
}
</script>
