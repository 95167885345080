<template>
  <div>
    <section class="py-24 px-4 sm:px-6 bg-brand-purple">
      <div class="mx-auto max-w-7xl">
        <div class="lg:flex justify-center lg:space-x-20">
          <a
            :href="baseUrl + car.href"
            v-for="car in cars"
            class="text-center cursor-pointer"
          >
            <div>
              <img
                :src="car.image"
                alt="Racer cartoon"
                class="hover:animate-wiggle mx-auto grayscale"
              />
              <h2
                class="text-2xl font-bold mt-3 text-white decoration-white uppercase"
              >
                {{ car.text }}
              </h2>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import car1 from '../assets/car1.svg'
import car2 from '../assets/car2.svg'
import car3 from '../assets/car3.svg'

let cars = [
  {
    image: car1,
    text: '0-1 Years',
    headlineText: '0-1 year olds',
    color: '#ff6633',
    activities: [{}, {}, {}, {}, {}, {}, {}, {}],
    id: '0-1',
    href: '/activities/0-1',
  },
  {
    image: car2,
    text: '1-2 Years',
    headlineText: '1-2 year olds',
    color: '#ff3399',
    activities: [{}, {}],
    id: '1-2',
    href: '/activities/1-2',
  },
  {
    image: car3,
    text: '2-3 Years',
    headlineText: '2-3 year olds',
    color: '#0D69B1',
    activities: [{}, {}, {}],
    id: '2-3',
    href: '/activities/2-3',
  },
]

import badge1 from '../assets/badge1.png'
import badge2 from '../assets/badge2.png'
import badge3 from '../assets/badge3.png'
import badge4 from '../assets/badge4.png'

const activities = ref([])
</script>
