<template>
  <div class="relative py-10 lg:py-32 bg-brand-orange">
    <div
      class="relative px-4 sm:px-6 lg:mx-auto lg:max-w-screen-2xl lg:flex justify-center lg:space-x-24 lg:px-8"
    >
      <div class="lg:pr-8 mb-20 lg:mb-0">
        <div class="mx-auto max-w-md sm:max-w-3xl lg:mx-0 flex flex-col h-full">
          <div
            class="prose prose-lg text-white"
            v-html="pitCrewData.copy"
          ></div>

          <div class="bg-brand-purple p-8 rounded-xl my-8">
            <img
              src="https://turbobabies.com/wp-content/uploads/2022/10/2-3-2-STORY-TIME-scaled.jpg"
              class="rounded-xl"
              alt=""
            />
          </div>
          <div
            class="text-white text-base mt-12 p-4 bg-[#E25223] rounded lg:mt-auto"
            v-html="pitCrewData.disclaimer_text"
          ></div>
        </div>
      </div>
      <div class="relative lg:w-4/5">
        <div class="p-12 bg-white rounded-xl">
          <h2 class="text-4xl text-brand-orange font-bold">
            Sign Up Today to Join the Pit Crew
          </h2>
          <h3
            class="text-2xl font-bold text-brand-blue max-w-[600px] mt-2 mb-6"
          >
            You'll receive FREE Tips & Activities to Rev Up Your Baby's Love for
            Learning
          </h3>
          <p class="text-lg text-gray-500 sm:mt-3">
            Click below to sign-up today:
          </p>

          <div class="mt-4">
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Select a tab</label>
              <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
              <select
                id="tabs"
                name="tabs"
                class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option
                  @select="
                    () => {
                      contactButton = 1
                      currentTab = forms.parent_form?.tab_title
                    }
                  "
                >
                  {{ forms.parent_form.tab_title }}
                </option>
                <option
                  @select="
                    () => {
                      contactButton = 2
                      currentTab = forms.partner_form?.tab_title
                    }
                  "
                >
                  {{ forms.partner_form?.tab_title }}
                </option>
              </select>
            </div>

            <div class="hidden sm:block">
              <nav class="flex space-x-4" aria-label="Tabs">
                <button
                  :class="[
                    currentTab === forms.parent_form?.tab_title
                      ? 'bg-blue-100 text-blue-700'
                      : 'text-gray-500 hover:text-gray-700',
                    'px-3 py-2 font-medium text-sm rounded-md',
                  ]"
                  @click="
                    () => {
                      contactButton = 1
                      currentTab = forms.parent_form?.tab_title
                    }
                  "
                >
                  {{ forms.parent_form.tab_title }}
                </button>
                <button
                  :class="[
                    currentTab === forms.partner_form?.tab_title
                      ? 'bg-blue-100 text-blue-700'
                      : 'text-gray-500 hover:text-gray-700',
                    'px-3 py-2 font-medium text-sm rounded-md',
                  ]"
                  @click="
                    () => {
                      contactButton = 2
                      currentTab = forms.partner_form?.tab_title
                    }
                  "
                >
                  {{ forms.partner_form?.tab_title }}
                </button>
              </nav>
            </div>
            <section class="mt-4">
              <div v-if="currentTab === 'parent' && data.parent_form">
                <h2 class="text-brand-orange text-3xl font-bold tracking-tight">
                  {{ data.parent_form.headline }}
                </h2>
                <div
                  class="mt-4 text-lg text-gray-500 sm:mt-3"
                  v-html="data.parent_form.body"
                ></div>
              </div>

              <div v-else-if="currentTab === 'partner' && data.parent_form">
                <h2 class="text-brand-orange text-3xl font-bold tracking-tight">
                  {{ data.partner_form.headline }}
                </h2>
                <div
                  class="mt-4 text-lg text-gray-500 sm:mt-3"
                  v-html="data.partner_form.body"
                ></div>
              </div>

              <slot></slot>

              <div
                class="prose prose-li:list-none prose-li:p-0 prose-ul:m-0 prose-ul:p-0 [&_input[type='text']]:w-full [&_input[type='tel']]:w-full [&_input[type='email']]:w-full [&_input[type='number']]:w-full [&_label]:font-bold [&_.name\_last>label]:!font-normal [&_.name\_last]:!text-sm [&_.name\_first>label]:!font-normal [&_.name\_first]:!text-sm [&_.ginput\_container\_checkbox_label]:!font-normal [&_.ginput\_container\_checkbox_label]:!ml-2 [&_.gform\_footer]:text-center [&_.gform\_footer]:mt-8"
              >
                <div
                  id="parentForm"
                  v-show="contactButton === 1"
                  class=""
                ></div>
                <div id="partnerForm" v-show="contactButton === 2"></div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, defineProps } from 'vue'
import family1 from '../assets/family1.jpg'
import turboFact from '../assets/turboFact.svg'
let { pitCrewData, forms } = defineProps(['forms', 'pitCrewData'])

const tab = ref({ current: 0 })
const contactButton = ref(1)
const data = ref({})
const currentTab = ref('parent')
const instance = getCurrentInstance()

onMounted(async () => {
  let res = await fetch(
    instance.proxy.baseUrl + '/wp-json/wp/v2/pages/125?acf_format=standard',
  )
  res = await res.json()
  data.value = res.acf
  currentTab.value = forms.parent_form.tab_title

  // document.querySelector('#parentFormGravity').style.display = 'block'
  // document.querySelector('#partnerFormGravity #gform_wrapper_1').style.display = 'block'
  document
    .querySelector('#parentForm')
    .appendChild(document.querySelector('#parentFormGravity #gform_wrapper_2'))
  document
    .querySelector('#partnerForm')
    .appendChild(document.querySelector('#partnerFormGravity #gform_wrapper_1'))
})
</script>
